// 20241012: 将 commonFoot.* 页面做为单独的入口文件进行打包管理
// 这样在包含该页面的其它页面上，不再需要打包这部分资源——只需要通过EJS引警引入该部分页面即可
// 这样就可有效防止 css 、js 等资源的重复加载问题

// CSS:
// 1、公共部分
// commonFooter 这部分也做为单独的入口文件进行管理
import "../public/css/commonFooter.css";

// JS:
// 1、公共部分
// 1.1、公共部分的静态资源部分，通过静态资源的方式导入第三方库
// foot 页面上的的静态资源注入该页面，不打包！其它页面上不再需要注入这些资源
// 后续扩展增加
// 1.2、所有页面的公共部分
// 开发阶段不引用该脚本，正式上线时测试后引入
// import '../public/js/commonFooter.js';

