// 20241012: 将 commonHead.* 页面做为单独的入口文件进行打包管理
// 这样在包含该页面的其它页面上，不再需要打包这部分资源——只需要通过EJS引警引入该部分页面即可
// 这样就可有效防止 css 、js 等资源的重复加载问题

// CSS:
// 1、公共部分
import "../public/css/global.css";
import "../public/css/commonHead.css";
import "../public/css/messageDialogBox.css";
// commonFooter 这部分也做为单独的入口文件进行管理
// import "../public/css/commonFooter.css";

// JS:
// 1、公共部分
// 1.1、公共部分的静态资源部分，通过静态资源的方式导入第三方库
// 每个页面上都需要的第三方静态资源通用性，做为静态资源注入该页面，不打包！其它页面上不再需要注入这些资源
// import '../public/js/ejs.min.js';
// '/js/axios/dist/axios.min.js';
// 1.2、所有页面的公共部分
// import '../public/js/logToConsoleAndServer.js';  // 这个文件已经在 mountGlobalObjects 中做了引入
// 20241115：将 mountGlobalObjects.js 文件做为公共资源，在业务逻辑JS之前 提前引入加载。这样不需在业务逻辑JS文件中引入操作
import '../public/js/common/mountGlobalObjects';
import '../public/js/commonHead.js';
import '../public/js/messageDialogBox.js';
import '../public/js/common/clickFlame.js';

